<template>
  <div id="dvCharts3" style="width: 100%; height: 100%"></div>
</template>

<script>
import * as echarts from 'echarts'
var chart3
export default {
  props: {
    option: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  watch: {
    option() {
      this.initEchartData()
    }
  },
  methods: {
    initEchartData() {
      chart3 = echarts.init(document.getElementById('dvCharts3', 'light'))
      chart3.setOption(this.option)
    }
  }
}
</script>

<style>
</style>
