<template>
  <div class="right-chart-3">
    <!-- <dv-charts class="lc2-chart" :option="option" /> -->
    <dv-charts-3 class="lc2-chart" :option="option" />
  </div>
</template>

<script>
import * as assetsApi from '@/api/ams/assets'
import dvCharts3 from './dv-charts-3.vue'
export default {
  name: 'RightChart3',
  components: {
    dvCharts3
  },
  data() {
    return {
      option: {
        series: [
          {
            type: 'pie',
            data: [{ name: '空闲', value: 0 }],
            radius: ['45%', '65%'],
            label: {
              show: true,
              position: 'outside',
              formatter: '{b}\n{d}%'
            }
          }
        ],
        color: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b']
      }
    }
  },
  methods: {
    setConfig(data) {
      assetsApi
        .stateAssetsReport(data.id)
        .then((res) => {
          if (res.code === 0) {
            if (res.data.length === 0) {
              this.option = {
                series: [
                  {
                    type: 'pie',
                    radius: ['45%', '65%'],
                    label: {
                      color: 'white',
                      show: true,
                      position: 'outside',
                      formatter: '{b}\n{d}%'
                    },
                    data: [{ name: '空闲', value: 0 }]
                  }
                ],
                color: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b']
              }
            } else {
              res.data.forEach((item) => {
                item.value = item.count
              })
              this.option = {
                series: [
                  {
                    type: 'pie',
                    radius: ['45%', '65%'],
                    label: {
                      color: 'white',
                      show: true,
                      position: 'outside',
                      formatter: '{b}\n{d}%'
                    },
                    data: res.data
                  }
                ],
                color: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b']
              }
            }
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less">
.right-chart-3 {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;

  .lc2-header {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    text-indent: 20px;
    margin-top: 10px;
  }

  .lc2-details {
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .lc2-chart {
    height: calc(~'100%');
  }
}
</style>
